import React from 'react';
import { Nav } from 'react-bootstrap';

const LandingRightSideNavItem = () => {
  return (
    <Nav navbar className="ms-auto">
      {}
      <Nav.Item>
        <a className="navItemMenuLanding" href="#features">
          Features
        </a>
      </Nav.Item>
      <Nav.Item>
        <a className="navItemMenuLanding" href="#get_started">
          Get Started
        </a>
      </Nav.Item>
      <Nav.Item>
        <a className="navItemMenuLanding" href="#pricing">
          Pricing
        </a>
      </Nav.Item>
      <Nav.Item>
        <a className="navItemMenuLanding" href="/dashboard/main">
          Login
        </a>
      </Nav.Item>
    </Nav>
  );
};

export default LandingRightSideNavItem;
