import { isPublicDomain } from 'http/domainUtils';

/* ---------------------------------
   1. DEFINIMOS LAS VERSIONES PRIVADAS
----------------------------------- */
const privateDashboardRoutes = {
  label: 'Dashboard',
  labelDisable: false,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      children: [
        {
          name: 'Global overview',
          to: '/dashboard/main',
          icon: 'chart-bar',
          exact: true,
          active: true
        },
        {
          name: 'Commits',
          to: '/dashboard/commits',
          icon: 'code',
          active: true
        },
        {
          name: 'Pull requests',
          to: '/dashboard/prs',
          icon: 'code-branch',
          active: true
        },
        {
          name: 'Investment balance',
          to: '/dashboard/investment-balance',
          icon: 'balance-scale-left',
          active: true,
          badge: {
            type: 'success',
            text: 'New'
          }
        },
        {
          name: 'Developers',
          to: '/dashboard/developers',
          icon: 'users',
          active: true
        }
      ]
    },
    {
      name: 'Reports',
      active: true,
      icon: 'paper-plane',
      children: [
        {
          name: 'Weekly report',
          to: '/reports/week',
          icon: 'calendar-day',
          exact: true,
          active: true,
          badge: {
            type: 'success',
            text: 'New'
          }
        },
        {
          name: 'Monthly report',
          to: '/reports/month',
          icon: 'calendar-alt',
          exact: true,
          active: true,
          badge: {
            type: 'success',
            text: 'New'
          }
        }
      ]
    }
  ]
};

const privateAppRoutes = {
  label: 'app',
  children: [
    {
      name: 'Account',
      icon: 'user',
      to: '/account',
      active: true
    },
    {
      name: 'Plan',
      icon: 'credit-card',
      to: '/plan',
      active: true
    },
    {
      name: 'Settings',
      icon: 'cog',
      to: '/settings',
      active: true
    },
    {
      name: 'FAQ',
      icon: 'question-circle',
      to: '/faq',
      active: true
    },
    {
      name: 'Support',
      icon: 'comments',
      to: '/support',
      active: true
    }
  ]
};

/* ---------------------------------
   2. DEFINIMOS LAS VERSIONES PÚBLICAS
----------------------------------- */
const publicDashboardRoutes = {
  label: 'Dashboard',
  labelDisable: false,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      children: [
        {
          name: 'Global overview',
          to: '/dashboard/main',
          icon: 'chart-bar',
          exact: true,
          active: true
        },
        {
          name: 'Commits',
          to: '/dashboard/commits',
          icon: 'code',
          active: true
        },
        {
          name: 'Pull requests',
          to: '/dashboard/prs',
          icon: 'code-branch',
          active: true
        },
        {
          name: 'Investment balance',
          to: '/dashboard/investment-balance',
          icon: 'balance-scale-left',
          active: true,
          badge: {
            type: 'success',
            text: 'New'
          }
        },
        {
          name: 'Developers',
          to: '/dashboard/developers',
          icon: 'users',
          active: true
        }
      ]
    }
  ]
};

const publicAppRoutes = {
  label: 'app',
  children: [
    {
      name: 'FAQ',
      icon: 'question-circle',
      to: '/faq',
      active: true
    },
    {
      name: 'Support',
      icon: 'comments',
      to: '/support',
      active: true
    }
    // No incluyes "Account", "Plan", "Settings", etc.
  ]
};

/* ---------------------------------
   3. ASIGNAMOS A dashboardRoutes y appRoutes
      DEPENDIENDO DEL SUBDOMINIO
----------------------------------- */

// Usamos "let" para poder reasignar
let dashboardRoutes;
let appRoutes;

if (isPublicDomain()) {
  dashboardRoutes = publicDashboardRoutes;
  appRoutes = publicAppRoutes;
} else {
  dashboardRoutes = privateDashboardRoutes;
  appRoutes = privateAppRoutes;
}

/* ---------------------------------
   4. EXPORTS FINALES
----------------------------------- */
export { dashboardRoutes, appRoutes };

// También se podría exportar como default un array
export default [dashboardRoutes, appRoutes];
