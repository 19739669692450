import { CloseButton } from 'components/common/Toast';
import DashboardFilterDevelopersProvider from 'components/dashboards/DashboardFilterDevelopersProvider';
import DashboardFilterRepositoriesProvider from 'components/dashboards/DashboardFilterRepositoriesProvider';
import DashboardFiltersProvider from 'components/dashboards/DashboardFiltersProvider';
import AppContext from 'context/Context';
import axiosInstance from 'http/axiosConfig';
import is from 'is_js';
import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import MainRoutes from 'routes';
import { toast } from 'react-toastify';
import './App.css';
import LoginPage from './loginPage';
import { isPublicDomain } from 'http/domainUtils';
const checkIsLogged = () => {
  if (window.location.search.toString().includes('token=')) {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    localStorage.setItem('accessToken', token);
    return true;
  } else {
    if (localStorage.getItem('accessToken') != null) {
      return true;
    } else {
      return false;
    }
  }
};

const App = () => {
  var landing = false;
  if (window.location.pathname.toString() === '/') {
    landing = true;
  }
  const [isLanding] = useState(landing);
  const isLogged = checkIsLogged();
  const [isLogin] = useState(isLogged);
  const [isInitData, setIsInitData] = useState(false);

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  // Set the initial date to 15 days ago
  const fifteenDaysAgo = new Date();
  fifteenDaysAgo.setDate(fifteenDaysAgo.getDate() - 90);

  const dateStart =
    localStorage.getItem('filterStartDate') !== null
      ? new Date(localStorage.getItem('filterStartDate'))
      : fifteenDaysAgo;
  const dateEnd =
    localStorage.getItem('filterEndDate') !== null
      ? new Date(localStorage.getItem('filterEndDate'))
      : new Date();

  const formattedStartDate =
    dateStart.getDate() +
    '/' +
    parseInt(dateStart.getMonth() + 1) +
    '/' +
    dateStart.getFullYear();

  const formattedEndDate =
    dateEnd.getDate() +
    '/' +
    parseInt(dateEnd.getMonth() + 1) +
    '/' +
    dateEnd.getFullYear();

  const [filters, setFilters] = useState({
    id: 50,
    startDate: formattedStartDate,
    endDate: formattedEndDate
  });

  const filtersValue = { filters, setFilters };

  const [developersFilter, setDevelopersFilter] = useState({
    developers: 'developers'
  });
  const developersFilterValue = { developersFilter, setDevelopersFilter };

  const [repositoriesFilter, setRepositoriesFilter] = useState({
    repositories: 'repositories'
  });
  const repositoriesFilterValue = { repositoriesFilter, setRepositoriesFilter };
  const mainRouter = (
    <Router basename={process.env.PUBLIC_URL}>
      <MainRoutes />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeButton={CloseButton}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </Router>
  );
  useEffect(() => {
    if (localStorage.getItem('waitingInitAppResponse') === null) {
      localStorage.setItem('waitingInitAppResponse', true);

      axiosInstance
        .get('/get-init-app/', {})
        .then(response => {
          const data_loaded_for_organization = response.data.data_loaded;
          const user_name = response.data.user_name;
          const user_avatar_url = response.data.user_avatar_url;
          const organization = response.data.organization;
          const data_is_processing = response.data.data_is_processing;
          const historical_data_is_processing =
            response.data.historical_data_is_processing;
          const developers_map_is_processing =
            response.data.developers_map_is_processing;
          const plan = response.data.plan;
          const daysLeftInFreeTrial = response.data.days_left_in_free_trial;
          const loadHistoricalDataPurchase =
            response.data.load_historical_data_purchase;

          var userData = {
            user_name: user_name,
            user_avatar_url: user_avatar_url,
            organization: organization,
            data_loaded_for_organization: data_loaded_for_organization,
            data_is_processing: data_is_processing,
            developers_map_is_processing: developers_map_is_processing,
            plan: plan,
            daysLeftInFreeTrial: daysLeftInFreeTrial,
            loadHistoricalDataPurchase: loadHistoricalDataPurchase
          };

          // Storing the JSON object in localStorage
          localStorage.setItem('userData', JSON.stringify(userData));
          setIsInitData(true);
          var pathname = window.location.pathname;
          if (data_is_processing && pathname != '/') {
            if (historical_data_is_processing) {
              toast.warning(
                `Thanks for your purchase! We are processing your historical data from the past few months. Soon, it will be fully available on the dashboard!`,
                { position: 'top-right', autoClose: 20000 }
              );
            } else {
              toast.warning(
                `We have processed the last 7 days of data. You may not see data from repositories or contributors
                  that haven't been active in the past 7 days. You can use Gitlights for free for the next 15 days. 
                  You have the option to load more historical data in the plans section.`,
                { position: 'top-right', autoClose: 20000 }
              );
            }
          }
          localStorage.removeItem('waitingInitAppResponse');
        })
        .catch(error => {
          localStorage.removeItem('waitingInitAppResponse');
          console.error(error);
        });
    }

    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  const renderDashboard = () => {
    return (
      <DashboardFiltersProvider value={filtersValue}>
        <DashboardFilterDevelopersProvider value={developersFilterValue}>
          <DashboardFilterRepositoriesProvider value={repositoriesFilterValue}>
            {mainRouter}
          </DashboardFilterRepositoriesProvider>
        </DashboardFilterDevelopersProvider>
      </DashboardFiltersProvider>
    );
  };

  // Add isPublic check
  const isPublic = isPublicDomain();

  return (
    <>
      {!isPublic ? (
        <>
          {!isLanding ? (
            <>
              {!isLogin ? (
                <LoginPage />
              ) : isInitData ? (
                renderDashboard()
              ) : (
                <Spinner
                  className="position-absolute start-50"
                  animation="border"
                  size="xl"
                />
              )}
            </>
          ) : (
            <>{mainRouter}</>
          )}
        </>
      ) : isInitData ? (
        renderDashboard()
      ) : (
        <Spinner
          className="position-absolute start-50"
          animation="border"
          size="xl"
        />
      )}
    </>
  );
};

export default App;
